<template>
  <v-row class="product-row">
    <v-col
      md="1"
      cols="3"
      class="product-col-left">
      <gw-product-image
        :src="product.photoUrls[0]"
        class="cursor-pointer"
        @click="previewImg(product.photoUrls[0])" />
    </v-col>
    <v-col
      class="product-detail-left"
      cols="9"
      md="5">
      <div>
        <b>ชื่อสินค้า: </b>{{ product.name }}
      </div>
      <div>
        <b>หมวดหมู่สินค้า: </b>{{ mapCategories(product.categories) }}
      </div>
      <div>
        <b>แบรนด์: </b>{{ product.brand }}
      </div>
      <div>
        <b>แท็ก: </b>
        <v-chip
          v-for="(tag, i) in product.tags"
          :key="`${product.id}-tag-${tag}-${i}`"
          class="ma-1"
          text-color="white"
          color="#c379aa"
          small>
          {{ tag.name || tag }}
        </v-chip>
      </div>
    </v-col>
    <v-col
      md="5"
      cols="12"
      class="product-col-right">
      <v-row class="product-detail">
      </v-row>
      <v-row class="product-skus mt-1">
        <v-col cols="12">
          <v-simple-table
            style="border: 1px solid #cfcfcf;"
            class="skus-table pa-3 pt-0"
            dense>
            <thead>
              <tr>
                <th>
                  Code
                </th>
                <th class="text-center">
                  Options
                </th>
                <th class="text-center">
                  Price
                </th>
                <th class="text-center">
                  Stock
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(sku, i) in product.skus"
                :key="`skus-${i}`">
                <td>
                  {{ sku.code }}
                </td>
                <td align="center">
                  {{ sku.color.name }} - {{ sku.size.name }}
                </td>
                <td align="center">
                  {{ sku.price | showFullPriceFormat() }}
                </td>
                <td align="center">
                  <span v-if="stockReady">
                    {{ getQtySkuByWarehouse(sku) }} ({{ getReservedQtySkuByWarehouse(sku) }})
                  </span>
                  <span v-else>
                    -
                  </span>
                </td>
                <th class="text-center">
                  <v-btn
                    v-if="sku.id !== selectedSkuId"
                    class="preview-image-close"
                    icon
                    @click="changeSku(sku)">
                    <v-icon size="medium">
                      mdi-magnify
                    </v-icon>
                  </v-btn>
                </th>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { getAuthDecode } from '@/assets/js/Authentication'
import { mapActions } from 'vuex'
import SizeValues from '@/assets/js/SizeValues'

export default {
  props: {
    productId: {
      type: String,
      required: true,
      default: ''
    },
    selectedSkuId: {
      type: String,
      required: true,
      default: null
    },
    productName: {
      type: String,
      required: true,
      default: ''
    },
    model: {
      type: String,
      required: true,
      default: ''
    },
    brand: {
      type: String,
      required: true,
      default: ''
    },
    variant: {
      type: String,
      required: false,
      default: ''
    },
    skus: {
      type: Array,
      required: true,
      default: () => []
    },
    categories: {
      type: Array,
      required: true,
      default: () => []
    },
    tags: {
      type: Array,
      required: true,
      default: () => []
    },
    photoUrls: {
      type: Array,
      required: true,
      default: () => []
    },
    warehouseId: {
      type: Number,
      required: true,
      default: 0
    },
    warehouseName: {
      type: String,
      required: true,
      default: 'Main'
    }
  },
  data () {
    return {
      stockReady: true
    }
  },
  computed: {
    product () {
      return {
        id: this.productId,
        name: this.productName,
        model: this.model,
        brand: this.brand,
        variant: this.variant,
        skus: this.sortSize(this.skus),
        categories: this.categories,
        tags: this.tags,
        photoUrls: this.photoUrls
      }
    },
    warehouse () {
      this.reloadStock()
      return {
        id: this.warehouseId,
        name: this.warehouseName
      }
    },
    disabledButton () {
      const { role } = getAuthDecode()
      return this.warehouse.id === 0 || !(role === 'management' || role === 'developer')
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    changeSku (sku) {
      this.$emit('change-sku', sku)
    },
    previewImg (img) {
      this.$emit('preview:image', img)
    },
    reloadStock () {
      this.stockReady = false
      setTimeout(() => {
        this.stockReady = true
      }, 1)
    },
    checkReserveSource (sku, warehouseId) {
      this.$router.push({
        name: 'ReserveSource',
        params: { skuId: sku.id },
        query: { warehouseId }
      })
    },
    sortSize (skus) {
      return skus.sort((a, b) => SizeValues(a.size.name) - SizeValues(b.size.name))
    },
    mapCategories (data) {
      return data.map((r) => r).join(', ')
    },
    getLastNumber (num = 0) {
      const number = num.toString()
      return number[number.length - 1]
    },
    getQtySkuByWarehouse (sku) {
      const stock = sku.stock.find((r) => r.warehouse.id === +this.warehouseId)
      const onHandQty = stock && stock.onHandQty ? stock.onHandQty : 0
      return onHandQty
    },
    getReservedQtySkuByWarehouse (sku) {
      const stock = sku.stock.find((r) => r.warehouse.id === +this.warehouseId)
      const onReservedQty = stock && stock.onReservedQty ? stock.onReservedQty : 0
      return onReservedQty
    }
  }
}
</script>

<style scoped>
img.img-lzd {
  display: block;
}
.skus-table th {
  text-transform: capitalize;
}
.stock-table {
  border: 1px solid  #858585;
  margin: -1px;
}
.product-image .main-image {
  display: block;
  margin: auto;
}
h4.product-name {
  margin-bottom: .5rem;
}
</style>

<style lang='scss'>
.stock-adjust {
  display: flex;
  justify-content: center;
}
.select-stock-adjust-operation {
  min-width: 75px;
  max-width: 75px;
  width: 75px;
  font-size: 11px;
  input {
    display: none;
  }
}
.input-stock-adjust-operation {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.stock-adjust {
  .v-input__icon {
    width: 10px;
    min-width: unset;
  }
  .v-input__icon .mdi:before {
    font-size: 18px;
  }
}
.reserve-text {
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
